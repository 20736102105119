<template>
  <div class="oversea">
    <div class="banner"  @click="dialogVisible=true">
      <div class="lk_slide_div">
        <h3 class="show-title">精准获取海外流量</h3>
        <p>为跨境电商企业提供海外推广服务</p>
      </div>
      <div class="lk_slide_btn">
        <el-button @click="dialogVisible=true">
          立刻咨询
        </el-button>
      </div>
    </div>
    <div style="height: 70px;display: flex" class="bread">
      <div class="w1200 bar">
        <a href="home">首页</a>&gt;<a href="/oversea">海外推广</a>
      </div>
    </div>
    <div class="brand">
      <div class="title w1200">
        <img style="height: 62px;position: relative;top: -10px;" src="../../../assets/img/icons/qudao.png">
        <div class="tit">
          <h3>拥有8大海外推广渠道</h3>
        </div>
      </div>
      <b-container style="margin-top: 80px;padding-left: 0 !important;padding-right: 0 !important;">
        <carousel
          :items="8"
          :loop="false"
          :autoplay="true"
          :nav="false"
          :margin="30"
          :autoplay-hover-pause="false"
          :dots="true"
          :smart-speed="1000"
          :stagePadding="20"
          :responsive="{
            0: {
              items: 3
            },
            480: {
              items: 3
            },
            576: {
              items: 3
            },
            768: {
              items: 5
            },
            960: {
              items: 6
            },
            1200: {
              items: 8
            }
          }"
          class="brand-slider"
        >
          <div v-for="(item,index) in brandList" :key="index" class="item">
            <img :src="item" alt="img" style="height: 120px;width: 120px">
          </div>
        </carousel>
      </b-container>
    </div>
    <div class="qudao container-box gary">
      <div class="title w1200">
        <img style="height: 62px;position: relative;top: -10px;" src="../../../assets/img/icons/youshi.png">
        <div class="tit">
          <h3>多渠道推广的优势</h3>
        </div>
      </div>
      <div class="card-box w1200">
        <div class="value-items" v-for="(item,index) in quDaoList" :key="index">
          <svg-icon :iconClass="item.iconClass" className="svgIcon"></svg-icon>
          <h4>{{ item.title }}</h4>
          <p>{{ item.p }}</p>
        </div>
      </div>
    </div>
    <div class="jieju container-box">
      <div class="title w1200">
        <img style="height: 62px;position: relative;top: -10px;" src="../../../assets/img/icons/jiejue.png">
        <div class="tit">
          <h3>VllShop能帮你做什么</h3>
        </div>
      </div>
      <div class="card-box w1200">
        <div class="domand-items" v-for="(item,index) in domandList" :key="index">
          <img class="svgIcon" :src="item.img">
          <h3>{{ item.title }}</h3>
          <p>{{ item.p }}</p>
        </div>
      </div>
    </div>
    <div class="custom container-box" style="background: #edf2fa">
      <div class="title w1200">
        <h3>10000+电商企业选择了Vllshop进行推广</h3>
      </div>
      <div class="card-box w1200 container">
        <div class="custom-items" v-for="(item,index) in customList" :key="index">
          <img :src="item.img" style="max-width: 480px"/>
          <h3>{{ item.title }}</h3>
          <p>{{ item.p }}</p>
        </div>
      </div>
    </div>
    <div class="btn container-box">
      <div class="title w1200">
        <h3>我们专注效果，助您获得更多海外订单</h3>
      </div>
      <el-button type="info"  @click="dialogVisible=true">立刻咨询</el-button>
    </div>
<!--    <div class="uqa container-box gary">-->
<!--      <div class="title w1200">-->
<!--        <img style="height: 62px;position: relative;top: -10px;" src="../../../assets/img/icons/wenti.png">-->
<!--        <div class="tit">-->
<!--          <h3>Facebook 开户常见问题</h3>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <el-dialog :visible.sync="dialogVisible" width="500px">
      <div>
        <p style="font-size:25px">即刻开启海外推广业务</p>
        <p style="margin-top:16px">请留下您的信息和需求，您的专属商务会在24小时内和您联系</p>
        <el-form ref="infoFrom" label-position="top" label-width="80px" :rules="infoRules" :model="infoForm">
          <el-form-item label="" prop="name">
            <el-input v-model="infoForm.name" placeholder="请输入您的姓名" class="v-form-input" style="margin-top:18px" />
          </el-form-item>
          <el-form-item label="" prop="phone">
            <el-input v-model="infoForm.phone" placeholder="请输入您的电话" class="v-form-input" />
          </el-form-item>
          <el-form-item label="" prop="email">
            <el-input v-model="infoForm.email" placeholder="请输入您的邮箱" class="v-form-input" />
          </el-form-item>
          <el-form-item label="" prop="company">
            <el-input v-model="infoForm.company" placeholder="请输入您的公司" class="v-form-input" />
          </el-form-item>
          <el-form-item prop="categoryId">
            <el-select v-model="infoForm.categoryId" placeholder="请选择行业类型" class="v-form-input">
              <el-option
                v-for="item in list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="infoForm.detail"
              style="width: 380px;"
              type="textarea"
              :rows="4"
              placeholder="请输入需求内容"
            />
          </el-form-item>
        </el-form>
        <div class="vs-btn">
          <div class="vs-btn-box">
            <el-button style="background-color: rgb(121, 53, 115);color: #fff;" size="default" @click="commit()">提交</el-button>
            <el-button size="default" @click="dialogVisible=false">关闭</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <oversea-footer-banner></oversea-footer-banner>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import { addcounseling, getList } from '@/api/register'
import carousel from 'vue-owl-carousel'
import { isvalidPhone, validatEmail } from '@/utils/validate'
import OverseaFooterBanner from '@/layout/footer/overseaFooterBanner'

export default {
  name: 'Oversea',
  components: {
    OverseaFooterBanner,
    carousel
  },
  data() {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入电话号码'))
      } else if (!isvalidPhone(value)) {
        callback(new Error('请输入正确的11位手机号码'))
      } else {
        callback()
      }
    }
    const validEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入电子邮箱'))
      } else if (!validatEmail(value)) {
        callback(new Error('请输入正确的电子邮箱'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      infoForm: {
        name: '',
        phone: '',
        email: '',
        company: '',
        categoryId: '',
        detail: ''
      },
      infoRules: {
        name: [
          { required: true, trigger: 'blur', message: '请输入姓名' }
        ],
        phone: [
          { required: true, trigger: 'blur', validator: validPhone }
        ],
        email: [
          { required: true, trigger: 'blur', validator: validEmail }
        ],
        company: [
          { required: true, trigger: 'blur', message: '请输入公司名称' }
        ],
        categoryId: [
          { required: true, trigger: 'blur', message: '请选择类型' }
        ]
        // detail: [
        //   { required: true, trigger: 'blur', message: '请输入密码' }
        // ]
      },
      list: [],
      brandList: [
        require('../../../assets/img/brand/facebook.png'),
        require('../../../assets/img/brand/google.png'),
        require('../../../assets/img/brand/instagram.png'),
        require('../../../assets/img/brand/pinterest.png'),
        require('../../../assets/img/brand/snapchat.png'),
        require('../../../assets/img/brand/tiktok.png'),
        require('../../../assets/img/brand/twitter.png'),
        require('../../../assets/img/brand/youtube.png')
      ],
      quDaoList: [
        {
          iconClass: 'facebook_icon1',
          title: '打响知名度',
          p: '根据不同的产品特点和面向的国家地区，选择合适的渠道组合，快速有效的进行品牌以及独立站的宣传，借此提高网站流量，发掘新客户'
        },
        {
          iconClass: 'facebook_icon2',
          title: '提升产品销量',
          p: '通过在海外网络平台上进行推广，吸引用户到网站，从而通过转化漏斗的数据进行网站用户体验的优化，实现产品销量的稳步提升'
        },
        {
          iconClass: 'facebook_icon3',
          title: '赢得忠实客户',
          p: '通过已购买客户的数据累计，获得更多的忠实用户。同时与客户建立关系，吸引更多的回头客，提升复购率和客单价'
        }
      ],
      domandList: [
        {
          img: require('../../../assets/img/icons/demand-icon1.png'),
          title: '独立站优化',
          p: 'VllShop根据整站运营经验，提供网站检测报告及独立站优化建议'
        },
        {
          img: require('../../../assets/img/icons/demand-icon2.png'),
          title: '多渠道推广方案',
          p: 'VllShop拥有多个渠道的海外推广经验，根据产品、目标受众、创意、预算占比等维度提供推广策略方案'
        },
        {
          img: require('../../../assets/img/icons/demand-icon3.png'),
          title: '多渠道广告投放',
          p: 'VllShop具有专业的投放团队，可以根据客户需求和投放目标进行多渠道的广告投放'
        },
        {
          img: require('../../../assets/img/icons/demand-icon4.png'),
          title: '数据管理系统',
          p: '自主开发数据管理系统，操作简单，极易上手，可实时通过数据报表优化推广方案，也可实时管理订单'
        }
      ],
      customList: [
        {
          img: require('../../../assets/img/support/hao.png'),
          title: '电动车品牌工厂',
          p: 'VllShop通过一站式海外网络推广的方案，将萌恒从江苏常州的小镇工厂，转变成为亚马逊电动车品牌排名头部的卖家，打造成为电动车行业的出海标杆。与VllShop合作全渠道推广期间全站ROI最高达20。'
        },
        {
          img: require('../../../assets/img/support/paimeite.png'),
          title: '3c品牌工厂',
          p: 'VllShop协助厦门的次美亚，通过2年内的3次众筹，共筹集1100万美元，从代工厂转变成为音频领域的明星品牌。众筹获得超160000支持者；产品被超过200家海外科技媒体报道，超300位海外网红推荐。'
        }
      ]
    }
  },
  created() {
    getList().then(res => {
      this.list = res.data
    })
  },
  methods: {
    commit() {
      this.$refs['infoFrom'].validate((valid) => {
        if (valid) {
          addcounseling(this.infoForm).then(res => {
            if (res.code === 0) {
              Message.success(res.msg)
              this.dialogVisible = false
            } else {
              Message.error(res.msg)
              this.dialogVisible = false
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//::v-deep .el-input__inner{
//  border: 1px solid #DCDFE6;
//  border-radius: 4px;
//}
.show-title{
  display: unset;
}
::v-deep .el-form-item__error{
  left:40px;
}
.v-form-input{
  height: 45px;
  width: 380px;
}
.vs-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:24px;
}
.vs-btn-box{
  display: flex;
  width: 380px;
  justify-content: space-between;
}
.banner{
  width: 100%;
  height: 500px;
  box-sizing: border-box;
  background: url("../../../assets/img/banner/banner-oversea-bg.png") no-repeat center center;
  //background-size: contain;
  display: flex;
  position: relative;
  flex-direction: column;
  color:#FFFFFF;
  h3{
    font-size: 5.6rem;
    letter-spacing: 4px;
    color:#FFFFFF;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  p{
    position: relative;
    font-size: 25px;
    text-align: left;
    top: -6px;
    left: -1px;
    letter-spacing: 1px;
  }
  .lk_slide_div {
    position: absolute;
    top: 20%;
    left: 17%;
    font-size: 46px;
    letter-spacing: 2px;
    color: white;
  }
  .lk_slide_btn {
    position: absolute;
    top: 58%;
    left: 17%;
    font-size: 21px;
    letter-spacing: 2px;
    color: white;
    .el-button{
      width: 150px;
      height: 55px;
      font-size: 2.2rem;
    }
  }
}
.w1200 {
  max-width: 1200px;
  margin: 0 auto;
  align-items: start;
  width: 100%;
}
.bread{
  display: flex;
  background: #f4f4f4;
  line-height: 70px;
  color: #666666;
  font-size: 18px;
  padding: 0 40px;
}
.bar{
  display: flex;
  align-items: center;
  color:#818478;
  font-size: 18px;
  text-align: left;
}
.title{
  display: flex;
  justify-content: center;
  padding: 80px 0 0 0;
  .tit{
    margin-left: 22px;
    padding-left: 25px;
    display: inline-block;
    position: relative;
    text-align: left;
    color: #333333;
  }
  .tit:before {
    content: "";
    position: absolute;
    left: 0;
    top: -6px;
    width: 2px;
    background: #333333;
    height: 58px;
  }
}
.owl-stage{
  overflow: hidden;
}
.owl-stage .item{
  width: 130px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    transition: all .5s ease-in-out;
  }
}
.brand{
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  h3{
    line-height: 46px;
    font-size: 32px;
  }

}

.item img:hover{
  transform: scale(1.25);
  transition: all .5s ease-in-out;

}
.container-box{
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  h3{
    line-height: 46px;
    font-size: 32px;
  }
}
.gary{
  background-color: rgb(245, 245, 245);
}
.card-box{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;
}
.card-box .value-items{
  width: 380px;
  height: 325px;
  background: #fff;
  border: 1px solid #f8f9fb;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, .06);
  border-radius: 4px;
  padding: 30px;
  margin: 10px 5px;
  text-align: center;
  .svgIcon{
    width: 10rem;
    height: 10rem;
    vertical-align: -0.15rem;
    fill: currentColor;
    overflow: hidden;
  }
  h4{
    margin: 20px 0px;
    font-size: 2.5rem;
  }
  p{
    position: relative;
    color: #8a94a6;
    font-size: 16px;
    line-height: 1.5;
  }
}
.card-box .domand-items{
  width: 285px;
  height: 325px;
  background: #FFF;
  border: 1px solid #F8F9FB;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 30px 25px;
  margin: 10px 5px;
  .svgIcon{
    width: 8rem;
    height: 8rem;
    vertical-align: -0.15rem;
    fill: currentColor;
    overflow: hidden;
  }
  h3{
    font-size: 25px;
    color: #202436;
    text-align: center;
    line-height: 20px;
    margin: 20px 0;
  }
  p{
    position: relative;
    color: #666;
    font-size: 16px;
    line-height: 30px;
  }
}
.custom{
  h3{
    font-size: 24px;
    color: #202b36;
    text-align: center;
    padding-top: 15px;
  }
}
.uqa{
  .title{
    padding: 50px 0 0 0;
  }
  h3{
    font-size: 24px;
    color: #202b36;
    text-align: center;
  }
}
.custom-items{
  width: 480px;
  margin: 50px;
  h3{
    font-size: 25px;
    color: #686aaf;
    text-align: left;
  }
  p{
    margin: 10px 0 30px;
    font-size: 16px;
    color: #6e7076;
    line-height: 28px;
    text-align: left;
  }
}
.btn.container-box{
  .el-button{
    margin: 36px auto 0;
    background: rgb(239,239,239);
    font-size: 1.4rem;
    width: 108px;
    height: 42px;
    border: none;
    color: rgb(51,122,183);
  }
}

@media only screen and (max-width:750px) {
   .banner{
     height: 600px;
     background: url("../../../assets/img/banner/banner-oversea-mobile-bg.png") no-repeat center 80% !important;
     background-size: 50vw auto;
   }
   .lk_slide_div {
    position: absolute;
    top: 30% !important;
    left: 17%;
    font-size: 25px;
    letter-spacing: 2px;
    color: white;
     p{
       display: none;
     }
  }
  .show-title{
    display: none;
  }
  .custom-items{
    img{
      width: 90vw;
    }
  }
  .lk_slide_btn{
    display: none;
  }
}
</style>
